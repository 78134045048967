<template>
  <div class="OpeningDynamic">
    <h2 class="is-size-3">
      Öffnungszeiten
    </h2>
    <OpeningTimeTable :times="GetOpeningWinter" />
    <Link to="Preise">
      <Button class="b">
        <span>zu den Preisen</span> <IconArrowRight />
      </Button>
    </Link>
  </div>
</template>

<script>
import OpeningTimes from "@/static_data/opening.json";
import OpeningTimeTable from "@/components/Elements/OpeningTimes/OpeningTimeTable";
import Button from "@/components/Elements/Button";
import IconArrowRight from "@/components/Elements/IconArrowRight";
import Link from "@/components/Elements/LinkForYellowBG";

export default {
  name: "OpeningDynamic",
  components: { OpeningTimeTable, Button, IconArrowRight, Link },
  data() {
    return {
      M: 3,
    };
  },
  computed: {
    GetOpeningWinter: function (){
      return OpeningTimes.def.Sommer
    },
  }
};
</script>

<style scoped>
.b {
  float: right;
}
</style>
