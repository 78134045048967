<template>
  <div class="OpenStatic">
    <h2 class="is-size-3">
      Öffnungszeiten
    </h2>
    <OpeningTimeTable :times="GetOpeningWinter" />
    <Link to="Preise">
      <Button class="b">
        <span>zu den Preisen</span> <IconArrowRight />
      </Button>
    </Link>
  </div>
</template>

<script>
import OpeningTimes from '@/static_data/opening.json'
import OpeningTimeTable from '@/components/Elements/OpeningTimes/OpeningTimeTable'
import Button from '@/components/Elements/Button'
import IconArrowRight from '@/components/Elements/IconArrowRight'
import Link from "@/components/Elements/LinkForYellowBG";

export default {
  name: 'OpeningStatic',
  components: {
    IconArrowRight,
    OpeningTimeTable, Button, Link},
  computed: {
    GetOpeningWinter: function (){
      return OpeningTimes.def.Sommer
    },
  }
}
</script>

<style scoped lang="sass">


.b
  float: right
</style>
